.whatWeOfferTwo{
  .wwItem{
    height: unset;
    margin-bottom: 20px;
    text-align: left;
    padding: 40px 30px;
    &.hasmtop{
      margin-top: 50px;
      @media (max-width: 991px) {
        margin-top: 0;
      }
    }
    .icon{
      max-width: 40px;
      margin-left: 0;
    }
    .content{
      h2{
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        color: #182538;
        text-align: left;
      }
      p{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        color: rgba(24, 37, 56, 0.8);
        text-align: left;
      }
    }
  }
  .cBtn{
    margin-top: 20px;
  }
}