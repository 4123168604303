.featuredItem2{
  padding: 30px;
  border-radius: 10px;
  transition: all 0.3s ease 0s;
  background: #dfefff;
  @media (max-width: 767px) {
    padding: 30px;
  }
  &:hover{
    background: #4640DD;
    h2,
    p{
      color: #ffffff;
    }
    .icon{
      img{
        filter: brightness(0) invert(1);
      }
    }
  }
  .icon{
    margin-bottom: 40px;
  }
  h2{
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 30px;
    color: #182538;
    margin-bottom: 25px;
    transition: all 0.3s ease 0s;
  }
  p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #182538;
    transition: all 0.3s ease 0s;
    cursor: pointer;
  }
}
.lightVersionWrapper{
  .featuredItem2{
    text-align: center;
    &:hover{
      background: #ffffff;
      -webkit-box-shadow: 0 20px 25px rgba(0,0,0,0.05);
      -moz-box-shadow: 0 20px 25px rgba(0,0,0,0.05);
      box-shadow: 0 20px 25px rgba(0,0,0,0.05);
      h2,
      p{
        color: #182538;
      }
    }
    .icon{
      width: 60px;
      height: 60px;
      line-height: 60px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      margin: auto;
      margin-bottom: 30px;
    }
  }
}