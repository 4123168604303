@import "../../mixin";
.contactUsWrapper{
  position: relative;
  z-index: 4;
  padding: 50px 0;
  @media (min-width: 992px) and (max-width: 1200px) {
    padding-bottom: 70px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 60px;
    padding-top: 30px;
  }
  @media (max-width: 767px) {
    padding: 0 0 20px;
  }

  &.h2Contact{
    padding-bottom: 140px;
    @media (min-width: 1201px) and (max-width: 1599px) {
      padding-bottom: 90px;
    }
    @media (min-width: 992px) and (max-width: 1200px) {
      padding-bottom: 70px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding-bottom: 60px;
    }
    @media (max-width: 767px) {
      padding-bottom: 50px;
    }
  }

  .thumb{
    margin-top: 150px;
    @media (min-width: 1440px) {
      margin-left: -150px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      margin-top: 0;
      text-align: center;
    }
    @media (max-width: 767px) {
      margin-top: 40px;
    }
  }
  .contactForm{
    @media (max-width: 1350px) {
      padding-bottom: 20px;
    }
    .textContent{
      padding-right: 20%;
      @media (max-width: 767px) {
        padding-right: 0;
      }
    }
    form{
      margin-top: 20px;
    }
  }
}