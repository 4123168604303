.testimonialCarouselTwo {
  padding-top: 60px;
  position: relative;
  z-index: 1;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 40px;
  }

  .slick-slider {
    margin: 0 -25px;
    padding: 0 15px;
  }

  .slick-slide {
    padding: 10px;
  }

  .slick-current {
    .s2Tst {
      -webkit-box-shadow: -1px 4px 13px rgba(0, 0, 0, 0.03);
      -moz-box-shadow: -1px 4px 13px rgba(0, 0, 0, 0.03);
      box-shadow: -1px 4px 13px rgba(0, 0, 0, 0.03);
    }
  }

  .s2Tst {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;

    p {
      font-style: italic;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      color: rgba(24, 37, 56, 0.8);
      margin-bottom: 25px;
    }

    .tstMeta {
      .thumb {
        height: 40px;
        width: 40px;
        float: left;
        overflow: hidden;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        margin-right: 20px;
      }

      .content {
        overflow: hidden;

        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #182538;
        }

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          color: rgba(24, 37, 56, 0.8);
          margin: 0;
        }
      }
    }
  }
}

.cornerShape {
  display: none;
}