.whatWeOffer {
  padding: 50px 0;
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
}

.wwItem {
  text-align: center;
  padding: 60px 30px;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  height: 100%;
  @media (max-width: 991px) {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  }

  .icon {
    width: 80px;
    margin: auto;
    margin-bottom: 35px;
    @media (max-width: 767px) {
      width: 60px;
    }
  }

  .content {
    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 33px;
      text-align: center;
      color: #182538;
      margin-bottom: 15px;
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 23px;
      }
      @media (max-width: 767px) {
        font-size: 21px;
      }
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 25px;
      text-align: center;
      color: rgba(24, 37, 56, 0.8);
    }
  }
}

.viewAll {
  text-align: center;
}