
.commentForm {
  padding-top: 50px;
}

.inputStyleBasic {
  width: 100%;

  div fieldset {
    border-color: #CCD0D7;
  }
}