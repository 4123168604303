.blogDetails {
  padding-bottom: 80px;
  @media (max-width: 767px) {
    padding-bottom: 40px;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    color: #182538;
    margin-bottom: 35px;
    @media (max-width: 767px) {
      font-size: 25px;
    }
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    text-align: justify;
    color: rgba(24, 37, 56, 0.8);
    margin-bottom: 25px;
  }

  blockquote {
    p {
      font-style: italic;
      font-size: 21px;
      line-height: 30px;
      text-align: justify;
      color: #182538;
      font-weight: 400;
    }
  }

  img {
    margin-bottom: 25px;
  }

  ul {
    padding-left: 18px;
    list-style-type: disc;

    li {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      text-align: justify;
      color: rgba(24, 37, 56, 0.8);
      letter-spacing: 0;
      margin-bottom: 4px;
    }
  }
  .lsocialLinks{
    margin-top: 40px;
    text-align: center;
    li{
      display: inline-block;
      margin: 0 3px;
    }
  }
}