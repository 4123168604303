.ourAchievements {
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 20px 0;
  }

  .achvLists {
    text-align: right;

  }
}

.achvSingle {
  text-align: left;
  margin-bottom: 30px;
  display: inline-flex;
  align-items: center;
  padding: 10px 35px 10px 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 50px 100px rgba(24, 37, 56, 0.1);
  margin-right: 40px;
  &:last-child{
    margin-bottom: 0;
  }
  @media (max-width: 1200px) {
    box-shadow: 0 7px 20px rgba(24, 37, 56, 0.05);
  }
  @media (max-width: 991px) {
    margin-right: 0;
    width: 100%;
  }

  &.secondItem {
    margin-right: 0;
  }

  .icon {
    height: 80px;
    width: 80px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    text-align: center;
    line-height: 80px;
    margin-right: 25px;
    @media (max-width: 767px) {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }
  }

  .content {
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 25px;
      color: #182538;
      @media (max-width: 767px) {
        font-size: 15px;
      }
    }
  }
}
