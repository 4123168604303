@import "../../mixin";

.testimonialCarousel {
  .tstCarousel {
    margin-top: -85px;
    position: relative;
    z-index: 1;
    @media (max-width: 767px) {
      margin-top: 0;
    }

    .slick-arrow {
      position: absolute;
      left: 42%;
      bottom: 0;
      z-index: 9;
      cursor: pointer;

      &.slick-next {
        bottom: 7px;
        left: auto;
        right: 42%;
      }
    }

    .slick-slide {
      padding: 0 100px;
      padding-top: 95px;
      margin-bottom: 40px;
      @media (max-width: 767px) {
        padding: 0 15px;
      }
    }
  }

  .tstItem {
    text-align: center;

    p {
      font-style: italic;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      color: rgba(24, 37, 56, 0.8);
      @media (max-width: 767px) {
        font-size: 17px;
      }
      @media (max-width: 767px) {
        text-align: center;
      }
    }

    .tstAuthor {
      margin-top: 40px;

      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #182538;
      }

      strong {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0;
        color: rgba(24, 37, 56, 0.8);
      }
    }
  }

  .arrowSliderWrapper {
    max-width: 320px;
    margin: auto;

    .arrowSlider {
      display: block;
      min-height: 140px;

      .avatar {
        border-radius: 50%;
        margin: auto;
        overflow: hidden;
        height: 70px;
        width: 70px;
        margin-top: 10px;
        border: 10px solid transparent;
        transition: all 0.3s ease 0s;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .slick-current {
        .avatar {
          height: 100px;
          width: 100px;
          box-shadow: 0 3px 24px rgba(0, 0, 0, 0.1);
          border-color: #ffffff;
        }
      }
    }
  }
}