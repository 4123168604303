.contactInfoList {
  position: relative;
  z-index: 1;
  padding: 50px 0;
  @media (min-width: 992px) and (max-width: 1200px) {
    padding: 30px 0 0;
  }
  @media (max-width: 991px) {
    padding: 30px 0;
  }

  .bgShape10 {
    position: absolute;
    left: 0;
    top: 35%;
  }

  .cntItem {
    background: #ffffff;
    text-align: center;
    -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
    padding: 30px 15px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    .iconBox {
      height: 80px;
      width: 80px;
      line-height: 80px;
      border-radius: 50%;
      margin: auto;
      margin-bottom: 30px;
    }

    h2 {
      font-size: 20px;
      color: #282828;
      font-weight: 500;
      line-height: 38px;
      margin-bottom: 20px;
      @media (min-width: 768px) and (max-width: 1200px) {
        font-size: 19px;
      }
    }

    ul {
      li {
        display: block;
        font-size: 15px;
        color: #767676;
        letter-spacing: 0;
        margin-bottom: 10px;
        @media (min-width: 768px) and (max-width: 1200px) {
          font-size: 16px;
        }
        @media (max-width: 767px) {
          font-size: 15px;
        }
      }
    }
  }
}