.similarPost {
  padding: 60px 0 50px;
  @media (max-width: 767px) {
    padding: 40px 0;
  }
  .singleBlog {
    h4 {
      margin-bottom: 0;
    }
  }
}