.workInquiry{
  padding: 50px 0;
  @media (min-width: 768px) and (max-width: 1200px) {
    padding: 30px 0;
  }
  @media (max-width: 767px) {
    padding: 20px 0;
  }
  .textContent{
    text-align: center;
  }
}