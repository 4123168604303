.breadCrumb {
  position: relative;
  z-index: 1;
  padding: 80px 0;
  @media (min-width: 1201px) and (max-width: 1599px) {
    padding: 70px 0;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    padding: 60px 0;
  }
  @media (max-width: 767px) {
    padding: 50px 0;
  }
  .breadCrumbContent {
    text-align: center;
    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 51px;
      line-height: 60px;
      text-align: center;
      color: #4640DD;
      text-transform: uppercase;
      @media (min-width: 992px) and (max-width: 1350px) {
        font-size: 41px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 36px;
      }
      @media (max-width: 767px) {
        font-size: 23px;
        line-height: 42px;
      }
    }
  }

  .bgShape2 {
    position: absolute;
    left: 0;
    top: -96px;
    z-index: -1;
    width: 100%;
    @media (max-width: 767px) {
      top: -59px;
    }
  }
}