.careerWrapper{
  padding: 50px 0;
  .careerPost{
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 40px 30px;
    background: #ffffff;
    margin-bottom: 20px;
    height: 100%;
    .tagLists{
      margin-bottom: 20px;
    }
    h2{
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 25px;
      color: #182538;
      margin-bottom: 15px;
    }
    p{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: rgba(24, 37, 56, 0.8);
    }
    .cBtn{
      margin-top: 40px;
      color: #1BDBAD;
      border-color: #1BDBAD;
      &:hover{
        background: #1BDBAD!important;
      }
    }
  }
}