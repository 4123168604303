.relatedPost{
  padding-top: 80px;
  @media (min-width: 1201px) and (max-width: 1599px) {
    padding-top: 60px;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    padding-top: 50px;
  }
  @media (max-width: 767px) {
    padding-top: 40px;
  }
  h4.rlvttitle{
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    color: #182538;
    margin-bottom: 20px;
  }
}