@import "../../mixin";
.sectionTitle{
  margin-bottom: 30px;
  text-align: center;
  @media (max-width: 1200px) {
    margin-bottom: 0;
  }
  strong{
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    margin-bottom: 9px;
    color: transparent;
    text-transform: uppercase;
    background-image: linear-gradient(to right, #021FFE, #33C1FF);
    -webkit-background-clip: text;
    background-clip: text;
  }
  h2{
    font-size: 36px;
    font-weight: 500;
    color: #182538;
    line-height: 51px;
    @media (min-width: 1201px) and (max-width: 1599px) {
      font-size: 35px;
      line-height: 45px;
    }
    @media (min-width: 992px) and (max-width: 1200px) {
      font-size: 32px;
      line-height: 42px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 31px;
      line-height: 42px;
    }
    @media (max-width: 767px) {
      font-size: 23px;
      line-height: 34px;
    }
  }
  p{
    font-size: 16px;
    color: #182538;
    margin: 0;
    line-height: 32px;
    margin-top: 23px;
    font-weight: 400;
    @media (min-width: 1201px) and (max-width: 1599px) {
      font-size: 16px;
    }
    @media (min-width: 768px) and (max-width: 1200px) {
      font-size: 15px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}