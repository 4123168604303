.contentSection {
  padding: 60px 0;
  position: relative;
  z-index: 1;
  @media (min-width: 992px) and (max-width: 1200px) {
    padding: 30px 0;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 30px 0;
  }
  @media (max-width: 767px) {
    padding: 20px 0;
  }

  .thumb {
    margin-left: -244px;
    @media (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 25px;
    }
    @media (max-width: 1600px) {
      margin-left: 0;
    }

    &.tright {
      margin-left: 0;
      margin-right: -244px;
      @media (max-width: 1599px) {
        margin-right: 0;
      }
    }
  }

  .textContent {
    padding-left: 30px;
    @media (max-width: 991px) {
      padding-left: 0;
    }
  }
}

.textContent {
  @media (max-width: 991px) {
    padding-left: 0;
  }

  &.tright {
    padding-left: 0;
    padding-right: 30px;
  }

  strong {
    display: inline-block;
    font-size: 19px;
    font-weight: 400;
    letter-spacing: 0;
    margin-bottom: 8px;
    color: transparent;
    text-transform: uppercase;
    background-image: linear-gradient(to right, #021FFE, #33C1FF);
    -webkit-background-clip: text;
    background-clip: text;
    @media (min-width: 992px) and (max-width: 1200px) {
      margin-bottom: 10px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 10px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 26px;
    }
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 45px;
    color: #182538;
    margin-bottom: 30px;
    @media (min-width: 992px) and (max-width: 1200px) {
      font-size: 33px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 32px;
    }
    @media (max-width: 767px) {
      font-size: 21px;
      line-height: 36px;
      margin-bottom: 30px;
    }
  }

  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    color: #182538;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 20px;
    color: rgba(24, 37, 56, 0.8);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
