.heroAreaWapper{
  padding: 100px 0 60px;
  position: relative;
  z-index: 1;
  @media (min-width: 992px) and (max-width: 1200px) {
    padding: 60px 0 90px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 60px 0 30px;
  }
  @media (max-width: 767px) {
    padding: 40px 0 0;
  }
  .heroContent{
    @media (max-width: 1350px) {
      padding: 0 15px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding-right: 20%;
    }
    &.textCenter{
      text-align: center;
    }
    strong{
      display: block;
      font-size: 21px;
      font-weight: 400;
      letter-spacing: 0;
      margin-bottom: 17px;

      color: transparent;
      background-image: -webkit-linear-gradient(left, #021FFE, #33C1FF, #fff); /* For Chrome and Safari */
      background-image:    -moz-linear-gradient(left, #021FFE, #33C1FF, #fff); /* For old Fx (3.6 to 15) */
      background-image:     -ms-linear-gradient(left, #021FFE, #33C1FF, #fff); /* For pre-releases of IE 10*/
      background-image:      -o-linear-gradient(left, #021FFE, #33C1FF, #fff); /* For old Opera (11.1 to 12.0) */
      background-image:         linear-gradient(to right, #021FFE, #33C1FF, #fff); /* Standard syntax; must be last */
      -webkit-background-clip: text;
      background-clip: text;
      @media (min-width: 1201px) and (max-width: 1599px) {
        font-size: 20px;
        margin-bottom: 15px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 20px;
        margin-bottom: 7px;
      }
      @media (max-width: 767px) {
        font-size: 17px;
        margin-bottom: 0;
      }
    }
    h2{
      font-size: 51px;
      font-weight: 700;
      color: #182538;
      line-height: 64px;
      letter-spacing: 0;
      margin-bottom: 31px;
      @media (min-width: 1201px) and (max-width: 1599px) {
        font-size: 46px;
      }
      @media (min-width: 992px) and (max-width: 1200px) {
        font-size: 38px;
        line-height: 53px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 43px;
        line-height: 54px;
      }
      @media (max-width: 767px) {
        font-size: 26px;
        line-height: 40px;
        font-weight: 500;
      }
    }
    p{
      font-size: 16px;
      font-weight: 400;
      color: #182538;
      line-height: 30px;
      margin-bottom: 40px;
      @media (max-width: 767px) {
        font-size: 15px;
        line-height: 28px;
        margin-bottom: 25px;
      }
    }
  }
  .hrRighThumb{
    @media (min-width: 1600px) {
      margin-right: -260px;
    }
    @media (max-width: 767px) {
      margin-top: 40px;
    }
  }
  .bgShape1{
    position: absolute;
    left: 0;
    top: -96px;
    z-index: -1;
    width: 100%;
  }
}