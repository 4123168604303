.footerWrapper {
  padding-top: 80px;
  padding-bottom: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: linear-gradient(180deg, #f9fbff, #D2EAFF);
  @media (min-width: 1201px) and (max-width: 1599px) {
    padding-top: 80px;
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    padding-top: 40px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 40px;
  }
  @media (max-width: 767px) {
    padding: 30px 0 0;
  }

  &.noShape {
    padding-top: 100px;
    @media (min-width: 992px) and (max-width: 1200px) {
      padding-top: 90px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding-top: 80px;
    }
    @media (max-width: 767px) {
      padding-top: 70px;
    }
  }

  .widget {
    margin-bottom: 40px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  .twiceHold {
    float: left;
    @media (min-width: 1350px) {
      padding-left: 72px;
    }
    &.haspl{
      padding-left: 100px;
    }
  }

  .widgetTitle {
    font-size: 20px;
    color: #4640DD;
    font-weight: 500;
    margin-bottom: 30px;
    font-family: 'Rubik', sans-serif;
  }

  .mobileApps {
    margin-top: 40px;

    p {
      font-size: 18px;
      color: #706c6e;
      letter-spacing: 0;
      font-weight: 500;
    }

    i {
      margin-left: 20px;

      &:first-child {
        color: #ff584e;
      }
    }
  }

  .footerContact {
    margin-bottom: 30px;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }

    > a {
      display: inline-block;
      margin-bottom: 30px;

      > img {
        width: 120px;
      }
    }

    p {
      font-size: 15px;
      color: #706c6e;
      letter-spacing: 0;
      font-weight: 500;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  .quickLinks {
    li {
      display: block;

      a {
        display: block;
        font-size: 15px;
        color: #706c6e;
        letter-spacing: 0;
        font-weight: 500;
        margin-bottom: 15px;
        @media (max-width: 767px) {
          font-size: 14px;
        }

        &:hover,
        &.active {
          color: #0EC64E;
        }
      }
    }
  }

  ul.socialLinks {
    margin-top: 20px;

    li {
      display: inline-block;

      a {
        display: block;
        margin-right: 15px;

        i {
          height: 30px;
          width: 30px;
          line-height: 30px;
          text-align: center;
          color: #ffffff;
          border-radius: 50%;
          background: #3B5998;
        }

        .fa-linkedin {
          background: #0077B5;
        }

        .fa-dribbble {
          background: #C13584;
        }

        .fa-twitter {
          background: #1DA1F2;
        }
      }
    }
  }

  .footerBgShape {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
}

.footerBottom {
  padding-top: 30px;
  @media (max-width: 767px) {
    padding-bottom: 20px;
  }

  p {
    font-size: 16px;
    color: #666163;
    font-weight: 400;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}