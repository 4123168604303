.ourAchievementsTwo{
  padding: 50px 0;
  @media (max-width: 991px) {
    padding: 30px 0;
  }
  .achvLists{
    .achvSingle{
      margin: 0;
      width: 100%;
    }
  }
  &.achivementThree{
    .achvSingle{
      padding: 50px 30px;
      text-align: center;
      display: block;
      .icon{
        margin: auto;
        margin-bottom: 30px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
      }
      .content{}
    }
  }
}