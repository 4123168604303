.whatWeOfferTwo {
  padding: 50px 0;
  @media (min-width: 768px) and (max-width: 1200px) {
    padding: 30px 0 0;
  }
  @media (max-width: 767px) {
    padding: 20px 0;
  }

  .wwItem {
    margin-bottom: 20px;
    text-align: left;
    padding: 40px 30px;

    &.hasmtop {
      margin-top: 50px;
    }

    .icon {
      max-width: 40px;
      margin-left: 0;
    }

    .content {
      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        color: #182538;
        text-align: left;
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        color: rgba(24, 37, 56, 0.8);
        text-align: left;
      }
    }
  }

  .cBtn {
    margin-top: 20px;
  }
}